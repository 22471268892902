import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Right from "../assets/images/Right-Experience.svg";
import Left from "../assets/images/Left-Experience.svg";
import { fetchData } from "../services/api"; // Ensure you have an API fetch utility

interface Speciality {
  id: string;
  speciality_name: string;
  page_id: string;
  image: string;
}

interface ExperienceCarouselProps {
  slidesToShow?: number;
}

const ExperienceCarousel: React.FC<ExperienceCarouselProps> = ({
  slidesToShow = 5,
}) => {
  const [specialities, setSpecialities] = useState<Speciality[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSpecialities = async () => {
      try {
        const response = await fetchData<{ specialities: Speciality[] }>({
          url: "pages/get",
          data: { pageId: 18 },
        });

        if (response?.data?.specialities) {
          setSpecialities(response.data.specialities);
        } else {
          throw new Error("No specialties data found.");
        }
      } catch (err: any) {
        setError("Failed to load specialties. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchSpecialities();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: Math.min(slidesToShow, 4) },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: Math.min(slidesToShow, 2) },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, dots: true, arrows: false },
      },
    ],
  };

  return (
    <div className="relative mt-16 px-4 sm:px-8">
      <h2 className="text-center uppercase text-2xl sm:text-3xl font-bold font-alumnisans mb-6 tracking-widest">
        Tailored Experiences for Every Traveler
      </h2>

      <div className="relative flex items-center justify-center mt-8">
        <Slider {...settings} className="w-full max-w-6xl">
          {specialities.map((speciality) => (
            <div key={speciality.id} className="px-2">
              <div className="relative h-[200px] sm:h-[260px] md:h-[320px] w-full max-w-[140px] sm:max-w-[160px] md:max-w-[210px] rounded-[20px] overflow-hidden shadow transition-transform duration-300 hover:scale-105 mx-auto">
                <img
                  src={speciality.image}
                  alt={speciality.speciality_name}
                  className="object-cover w-full h-full rounded-[20px]"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 rounded-[20px]">
                  <h3 className="text-white font-mplus2 tracking-widest text-xs sm:text-sm font-bold text-center">
                    {speciality.speciality_name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        {/* Decorative Images */}
        <div className="absolute top-0 right-0 hidden lg:block">
          <img src={Right} alt="Decorative Right" className="object-fill" />
        </div>
        <div className="absolute bottom-0 left-0 hidden lg:block">
          <img
            src={Left}
            alt="Decorative Left"
            className="h-40 sm:h-80 w-full object-fill"
          />
        </div>
      </div>
    </div>
  );
};

export default ExperienceCarousel;

import React from "react";
import HeaderMonthly from "../components/HeaderMonthly";
import TravelMonthSection from "../components/TravelMonthSection";
import BestPlacesToVisitSection from "../components/BestPlacesToVisitSection";
import MonthlyDestinationCard from "../components/MonthlyDestinationCard";
import MonthlyDestinationList from "../components/MonthlyDestinationList";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";

const MonthlyDestinationHighlights: React.FC = () => {
  return (
    <div>
      <HeaderMonthly />
      <TravelMonthSection />
      <BestPlacesToVisitSection />
      <MonthlyDestinationList/>
      <PlanningTrip  headingplanning="Where to next?
"
          paragraph="We’ll create a vacation you’ll remember forever."
        />
        <Clients/>
        <Footer/>

     
    </div>
  );
};

export default MonthlyDestinationHighlights;

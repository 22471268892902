import React from "react";
import { ImQuotesLeft } from "react-icons/im";

interface CarouselCardProps {
  text: string;
  author: string;
}

const CarouselCard: React.FC<CarouselCardProps> = ({ text, author }) => {
  return (
    <div className="py-6 flex-shrink-0 w-full max-w-[280px] sm:max-w-[320px] md:max-w-[360px] px-4 py-6 mx-auto bg-[#FDFDFD] rounded-lg shadow-md border border-gray-200 duration-300 hover:scale-105">
      {/* Quote Icon */}
      <div className="flex justify-center items-center text-2xl sm:text-3xl text-blue-900">
        <ImQuotesLeft />
      </div>

      {/* Quote Text */}
      <div className="mt-4 min-h-[80px] flex items-center justify-center">
        <p className="text-gray-600 text-center text-xs sm:text-sm md:text-base font-poppins tracking-wide leading-5 sm:leading-6">
          {text}
        </p>
      </div>

      {/* Author Name */}
      <div className="mt-4 text-blue-900 font-semibold text-sm sm:text-base text-center font-Poppins">
        {author}
      </div>
    </div>
  );
};

export default CarouselCard;

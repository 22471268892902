import React from "react";
import Navbar from "../components/Navbar";
import Aboutus from "../components/Aboutus";
import TravelDirector from "../components/TravelDirector";
import TravelAdvisors from "../components/TravelAdvisors";
import OurPhilosophy from "../components/OurPhilosophy";
import PrincipleCard from "../components/PrincipleCard";
import HeroSection from "../components/HeroSection";
import ServiceOverview from "../components/ServiceOverview";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";

const AboutusView: React.FC = () => {
  return (
    <div className="">
      <Navbar heading="ABOUT US" subHeading="Empowering traveler legacy." />
      <Aboutus />
      <TravelAdvisors />
      <TravelDirector />
      <OurPhilosophy />
      <PrincipleCard />
      <HeroSection />
      <ServiceOverview />
      <PlanningTrip
        headingplanning="Let us handle the details
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default AboutusView;

import React from "react";
import Navbar from "../components/Navbar";
import FamilyAgency from "../components/FamilyAgency";
import BannerFamily from "../components/BannerFamily";
import FamilyContact from "../components/FamilyContact";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import PlanningTrip from "../components/PlanningTrip";
import { useState, useEffect } from "react";
import BannerFamilyTravel from "../components/BannerfamilyTravel";
import { fetchData } from "../services/api";

const FamilyTravel: React.FC = () => {
  const [planningTripData, setPlanningTripData] = useState<any>({
    headingplanning: "",
    paragraph: "",
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningTripData = async () => {
      try {
        const response = await fetchData<{
          whereTo: { headingplanning: string; paragraph: string }[];
        }>({
          url: "pages/get",
          data: { pageId: 2 },
        });

        const whereToData = response?.data?.whereTo?.[0];
        console.log("API Response:", response?.data);
        console.log(whereToData);

        if (whereToData) {
          setPlanningTripData(whereToData);
        } else {
          throw new Error("No whereTo data found.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load Planning Trip data.");
      }
    };

    fetchPlanningTripData();
  }, []);
  console.log(planningTripData);
  return (
    <div className="">
      <Navbar
        heading="DENVER,S TOP RATED FAMILY  TRAVEL AGENCY"
        subHeading=""
      />
      <FamilyAgency />
      <BannerFamilyTravel />
      <FamilyContact />
      {error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <PlanningTrip
          headingplanning={planningTripData?.text}
          paragraph={planningTripData?.description}
        />
      )}
      <Clients />
      <Footer />
    </div>
  );
};

export default FamilyTravel;

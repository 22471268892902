import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface MottoData {
  id: string;
  text: string;
  description: string;
  image: string;
  buttonLink?: string;
  buttonText?: string;
  backgroundImage: string;
}

const RiverBanner: React.FC = () => {
  const [motto, setMotto] = useState<MottoData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMotto = async () => {
      try {
        const response: ApiResponse<{ motto: MottoData[] }> | null =
          await fetchData<{ motto: MottoData[] }>({
            url: "pages/get",
            data: { pageId: 9 }, // Replace with the correct page ID
          });

        const fetchedMotto = response?.data?.motto;

        if (fetchedMotto && fetchedMotto.length > 0) {
          setMotto(fetchedMotto[0]); // Assuming you want the first motto
        } else {
          throw new Error("Motto not found.");
        }
      } catch (error) {
        setError("Failed to load motto. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchMotto();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-600">{error}</p>;
  }

  return (
    <div
      className="relative h-screen bg-cover bg-center flex items-center justify-center"
      style={{
        backgroundImage: `url(${
          motto?.backgroundImage || "/default-background.jpg"
        })`,
      }}
    >
      <div className="border-2 border-gray-700 w-[95%] sm:w-[90%]  sm:p-10 lg:p-20 text-black   text-black overflow-auto max-h-[90%]">
        <h2 className="text-xl mt-3  lg:text-3xl font-semibold font-akshar text-center font-semibold uppercase font-alumnisans">
          {motto?.text}
        </h2>
        <p className="lg:text-base px-3 mt-6 sm:tracking-wide font-poppins sm:text-base md:text-lg text-gray-900 text-justify sm:text-left">
          {motto?.description}
        </p>
        {motto?.buttonLink && motto?.buttonText && (
          <div className="mt-6 text-center">
            <a
              href={motto.buttonLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white bg-blue-600 hover:bg-blue-700 py-2 px-4 rounded"
            >
              {motto.buttonText}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default RiverBanner;

import React from "react";
import Navbar from "../components/Navbar";
import Terms from "../components/Terms";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";

const TermsOfUse: React.FC = () => {
  return <div>
    <Navbar/>
    <Terms/>
    <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients/>
      <Footer/>

  </div>;
};

export default TermsOfUse;

import React from "react";
import logo from "../assets/images/Contact-Adv.png";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";

const ContactAd: React.FC = () => {
  return (
    <section className="bg-gray-50 py-12 md:py-16 px-6 md:px-32">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-16">
        {/* Left Section - Text and Form */}
        <div className="flex flex-col justify-center md:pr-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4 leading-tight">
            Curious About Travel?
          </h2>
          <p className="text-gray-700 mb-6 text-lg">
            Inspiring travel content and advice from our experts delivered
            straight to your inbox.
          </p>

          {/* Form */}
          <div className="flex flex-col sm:flex-row w-full gap-4">
            <input
              type="email"
              placeholder="Email address*"
              className="border border-gray-300 px-6 py-3 rounded-md w-full sm:w-auto text-gray-800 placeholder-gray-500 focus:ring-2 focus:ring-blue-500 transition duration-300"
            />
            <button className="bg-black text-white px-8 py-3 rounded-md hover:bg-gray-800 transition duration-300 transform hover:scale-105">
              Sign Up
            </button>
          </div>

          <hr className="my-6 border-gray-300 w-full" />

          <h3 className="text-lg font-semibold text-gray-900 mb-4">Follow Us On Social</h3>
          <div className="flex space-x-6 justify-center md:justify-start">
            <a
              href="#"
              className="text-blue-600 hover:text-blue-800 font-bold block border border-blue-600 rounded-full p-4 sm:p-2 md:p-4 transition duration-300 transform hover:scale-110"
            >
              <FaFacebookF size={24} />
            </a>
            <a
              href="#"
              className="text-pink-500 hover:text-pink-600 font-bold block border border-pink-500 rounded-full p-4 sm:p-2 md:p-4 transition duration-300 transform hover:scale-110"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="#"
              className="text-blue-700 hover:text-blue-900 font-bold block border border-blue-700 rounded-full p-4 sm:p-2 md:p-4 transition duration-300 transform hover:scale-110"
            >
              <GrLinkedinOption size={24} />
            </a>
            <a
              href="#"
              className="text-blue-400 hover:text-blue-600 font-bold block border border-blue-400 rounded-full p-4 sm:p-2 md:p-4 transition duration-300 transform hover:scale-110"
            >
              <FaTwitter size={24} />
            </a>
          </div>
        </div>

        {/* Right Section - Image */}
        <div className="flex justify-center md:justify-end">
          <img
            src={logo}
            alt="Travel content"
            className="h-auto w-full md:w-[80%] object-fill rounded-md "
          />
        </div>
      </div>
    </section>
  );
};

export default ContactAd;

// MonthlyDestinationCard.tsx
import React from "react";

interface CardProps {
  title: string;
  description: string;
  imageUrl: string;
}

const MonthlyDestinationCard: React.FC<CardProps> = ({
  title,
  description,
  imageUrl,
}) => {
  return (
    <div className="bg-white shadow-md rounded-md h-[500px] flex flex-col items-center justify-center ">
      <img src={imageUrl} alt={title} className="h-48 h-[60%] w-full object-cover" />
      <div className="p-4">
        <h3 className="text-base font-bold tracking-wide font-alumnisans font-alumnisans">{title}</h3>
        <p className="mb-4 text-sm  font-poppins text-gray-700 border-b-2  py-3">
          {description}
        </p>
        <a href="#" className="text-black font-akshar border-b border-black font-medium mt-0 mb-2 inline-block">
          Read More
        </a>
      </div>
    </div>
  );
};

export default MonthlyDestinationCard;

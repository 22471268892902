import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

interface CarouselNavigationProps {
  direction: "left" | "right";
  onClick: () => void;
  className?: string;
}

const CarouselNavigation: React.FC<CarouselNavigationProps> = ({
  direction,
  onClick,
  className,
}) => {
  return (
    <button
      className={`z-10 p-2 text-white bg-blue-900 rounded-full w-12 h-12 hover:bg-blue-700 flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      {direction === "left" ? (
        <IoIosArrowBack size={28} />
      ) : (
        <IoIosArrowForward size={28} /> 
      )}
    </button>
  );
};

export default CarouselNavigation;

import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Aboutus from "../components/Aboutus";
import TravelDirector from "../components/TravelDirector";
import TravelAdvisors from "../components/TravelAdvisors";
import OurPhilosophy from "../components/OurPhilosophy";
import PrincipleCard from "../components/PrincipleCard";
import HeroSection from "../components/HeroSection";
import ServiceOverview from "../components/ServiceOverview";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import NavMenu from "../components/NaveMenu";
import TravelOption from "../components/TravelOption";
import ContactSection from "../components/ContactSection";
import PromoBanner from "../components/PromoBanner";
import ExperienceCarousel from "../components/ExperienceCarousel";
import MonthGallery from "../components/MonthGallery";
import TabNavigation from "../components/TabNavigation";

const Month: React.FC = () => {
  return (
    <div>
      <NavMenu />

      <MonthGallery />
      <PromoBanner />
      <ContactSection />
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default Month;

import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface IntroductionData {
  id: string;
  text: string;
  description: string;
  image?: string;
  buttonLink?: string;
  buttonText?: string;
}

const PrivacyPolicyContent: React.FC = () => {
  const [introduction, setIntroduction] = useState<IntroductionData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchIntroductionData = async () => {
      try {
        const response: ApiResponse<{
          introduction: IntroductionData[];
        }> | null = await fetchData<{ introduction: IntroductionData[] }>({
          url: "pages/get",
          data: { pageId: 2 }, // Replace with the correct page ID
        });

        const introData = response?.data?.introduction;
        if (introData && introData.length > 0) {
          setIntroduction(introData);
        } else {
          throw new Error("Introduction data not found.");
        }
      } catch (error) {
        setError("Failed to load introduction data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchIntroductionData();
  }, []);

  if (loading) {
    return <p className="text-center">Loading...</p>;
  }

  if (error) {
    return <p className="text-red-500 text-center">{error}</p>;
  }

  return (
    <div className="min-h-screen p-12 mt-10">
      <section className="max-w-5xl mx-auto">
        {introduction.map((intro) => (
          <div key={intro.id} className="space-y-6 text-gray-700">
            <h2 className="text-xl tracking-wide font-alumnisans font-semibold  text-center sm:text-left">
              {intro.text}
            </h2>
            <p className="leading-relaxed font-akshar whitespace-pre-line text-center sm:text-left">
              {intro.description}
            </p>
            <div className="flex justify-center lg:justify-start mt-8">
              <a
                href={intro.buttonLink || "/home"}
                className="py-2 px-4 mb-6 lg:px-6 border border-black text-black font-semibold rounded-md hover:bg-gray-700 hover:text-white transition"
              >
                {intro.buttonText || "Back to Home"}
              </a>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default PrivacyPolicyContent;

import React, { useEffect, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { useNavigate } from "react-router-dom";
import blgbg from "../assets/images/Blogbg.png"; // Default Background Image
import authorImage from "../assets/images/Profile.png"; // Default Author Image
import { FaClock, FaComments } from "react-icons/fa";

interface Blog {
  blogId: string;
  title: string;
  description: string;
  image: string | null;
  createdAt: string;
  authorName: string | null;
  categoryNames: string[];
}

interface BlogApiResponse {
  success: boolean;
  message: string;
  error_code: number;
  data: Blog[];
}

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const BlogArticle: React.FC = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const fetchBlogs = async () => {
      const config: AxiosRequestConfig = {
        url: "/Blogs/list",
        headers: {
          token: STATIC_TOKEN,
          "Content-Type": "application/json",
        },
      };

      setLoading(true);
      try {
        const baseUrl = "https://demo.decordtech.com/tadmin/api/";
        const response = await axios.post<BlogApiResponse>(
          baseUrl + config.url,
          {},
          { headers: config.headers }
        );

        if (response.data.success) {
          setBlogs(response.data.data);
        } else {
          setError(response.data.message || "Failed to load blogs.");
        }
      } catch (err) {
        setError("An unexpected error occurred while fetching blogs.");
        console.error("Error fetching blogs:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleBlogClick = (blogId: string) => {
    navigate("/blogDetails", { state: { blogId } });
  };

  return (
    <div className="bg-gray-50 py-16">
      <h2 className="text-3xl  font-akshar font-bold mt-3 text-center mb-6 sm:mb-8 uppercase tracking-wide">
        Blogs
      </h2>
      <div className="px-4 sm:px-10 md:px-20 lg:px-28 xl:px-36">
        {loading ? (
          <p className="text-center text-gray-500">Loading blogs...</p>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : blogs.length === 0 ? (
          <p className="text-center text-gray-500">No blogs available at the moment.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {blogs.map((blog) => (
              <div
                key={blog.blogId}
                className="relative rounded-xl overflow-hidden shadow-lg max-w-md mx-auto transition transform hover:scale-105 hover:shadow-2xl cursor-pointer"
                onClick={() => handleBlogClick(blog.blogId)} // Navigate on click
              >
                <img
                  src={blog.image || blgbg}
                  alt={blog.title}
                  className="w-full h-64 object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
                <div className="absolute top-5 left-4 flex items-center space-x-4 text-yellow-400 text-xs">
                  <div className="flex items-center space-x-1">
                    <FaClock />
                    <span className="font-medium uppercase">{new Date(blog.createdAt).toLocaleTimeString()}</span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <FaComments />
                    <span className="uppercase">20 Comments</span>
                  </div>
                </div>
                <div className="absolute inset-0 p-6 flex flex-col justify-end text-white space-y-3">
                  <h3 className="text-lg font-semibold tracking-wide leading-tight">{blog.title}</h3>
                  <div className="flex items-center space-x-3 text-sm">
                    <img
                      src={authorImage}
                      alt={blog.authorName || "Unknown Author"}
                      className="w-8 h-8 rounded-full border-2 border-white"
                    />
                    <div>
                      <span className="font-semibold">{blog.authorName || "Unknown Author"}</span>
                      <p className="text-gray-300 text-xs">{new Date(blog.createdAt).toLocaleDateString()}</p>
                    </div>
                  </div>
                </div>
                <div className="absolute top-5 right-4 flex space-x-2">
                  {blog.categoryNames.map((category, idx) => (
                    <span
                      key={idx}
                      className="bg-yellow-400 text-black font-semibold uppercase px-2 py-1 rounded-sm text-xs shadow"
                    >
                      {category}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogArticle;

import React from "react";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import NavMenu from "../components/NaveMenu";
import TravelOption from "../components/TravelOption";
import ContactSection from "../components/ContactSection";
import PromoBanner from "../components/PromoBanner";
import ExperienceCarousel from "../components/ExperienceCarousel";
import { useState, useEffect } from "react";
import { fetchData } from "../services/api";


const Experience: React.FC = () => {
  const [planningTripData, setPlanningTripData] = useState<any>({
    headingplanning: "",
    paragraph: "",
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningTripData = async () => {
      try {
        const response = await fetchData<{
          whereTo: { headingplanning: string; paragraph: string }[];
        }>({
          url: "pages/get",
          data: { pageId: 18 },
        });

        const whereToData = response?.data?.whereTo?.[0];
        console.log("API Response:", response?.data);
        console.log(whereToData);

        if (whereToData) {
          setPlanningTripData(whereToData);
        } else {
          throw new Error("No whereTo data found.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load Planning Trip data.");
      }
    };

    fetchPlanningTripData();
  }, []);
  return (
    <div>
      <NavMenu />
      <ExperienceCarousel />
      <PromoBanner />
      <ContactSection />
      <div className="mt-12">
      {error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <PlanningTrip
          headingplanning={planningTripData?.text || "Default Heading"}
          paragraph={planningTripData?.description}
        />
      )}
      </div>
      <Clients />
      <Footer />
    </div>
  );
};

export default Experience;

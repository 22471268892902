import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface IntroductionData {
  id: string;
  text: string;
  description: string;
  image: string;
  buttonLink?: string;
  buttonText?: string;
}

const FamilyAgency: React.FC = () => {
  const [introduction, setIntroduction] = useState<IntroductionData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchIntroductionData = async () => {
      try {
        const response: ApiResponse<{
          introduction: IntroductionData[];
        }> | null = await fetchData<{ introduction: IntroductionData[] }>({
          url: "pages/get",
          data: { pageId: 2 }, // Replace with the correct page ID
        });

        const introData = response?.data?.introduction;
        if (introData && introData.length > 0) {
          setIntroduction(introData);
        } else {
          throw new Error("Introduction data not found.");
        }
      } catch (error) {
        setError("Failed to load introduction data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchIntroductionData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-600">{error}</p>;
  }

  return (
    <div>
      {introduction.map((item, index) => (
        <section
          key={item.id}
          className={`bg-white mt-6 sm:py-16 px-6 md:px-20 lg:px-32 ${
            index % 2 !== 0 ? "bg-gray-100" : ""
          }`}
        >
          <div
            className={`flex flex-col md:flex-row items-center gap-8 lg:gap-12 ${
              index % 2 !== 0 ? "md:flex-row-reverse" : ""
            }`}
          >
            <div className="w-full md:w-1/2 flex justify-center items-center md:items-start">
              <img
                src={item.image}
                alt={item.text}
                className="sm:w-full sm:h-full lg:w-full lg:h-full object-cover rounded-lg"
              />
            </div>

            <div className="w-full md:w-1/2 text-center md:text-left flex flex-col justify-center items-center md:items-start">
              <h2 className="mt-2 sm:mt-5  text-xl  lg:text-3xl font-semibold font-akshar mb-2 sm:mb-4">
                {item.text}
              </h2>
              <p className="lg:text-base sm:tracking-wide font-poppins sm:text-base md:text-lg text-gray-700 text-justify sm:text-left">
                {item.description}
              </p>

              {item.buttonLink && item.buttonText && (
                <a
                  href={item.buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-anekbangla inline-block py-2 px-4 mt-3 mb-3 border border-black text-black hover:bg-gray-700 hover:text-white transition rounded-md font-semibold"
                >
                  {item.buttonText}
                </a>
              )}
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default FamilyAgency;

import React from "react";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import NavMenu from "../components/NaveMenu";
import TravelOption from "../components/TravelOption";
import ContactSection from "../components/ContactSection";
import PromoBanner from "../components/PromoBanner";

const Destination: React.FC = () => {
  return (
    <div>
      <NavMenu />
      <TravelOption />
      <PromoBanner />
      <ContactSection />
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default Destination;

import React from 'react';
import Trending1 from "../assets/images/Treanding1.jpg";
import Trending2 from "../assets/images/Trending2.jpg";
import Trending3 from "../assets/images/Trending3.jpg";
import Trending4 from "../assets/images/Trending4.jpg";
import Trending5 from "../assets/images/Trending5.jpg";
import Trending6 from "../assets/images/Trending6.jpg";
import Travel from "../assets/images/Travel.png";
interface CardProps {
  image: string;
  category: string;
  location: string;
  title: string;
}

const Card: React.FC<CardProps> = ({ image, category, location, title }) => (
  <div className="border rounded-lg overflow-hidden shadow-sm">
    <img src={image} alt={title} className="w-full h-48 object-cover" />
    <div className="p-4">
      <p className="text-sm text-green-600 font-semibold uppercase">{category} | {location}</p>
      <h3 className="mt-2 text-lg font-semibold text-gray-800">{title}</h3>
    </div>
  </div>
);

const Trending: React.FC = () => {
  const cards = [
    {
      image: Trending1,
      category: 'Places',
      location: 'The USA',
      title: 'The best family vacations in the US',
    },
    {
      image: Trending3,
      category: 'Places',
      location: 'Inida',
      title: 'Have you seen the black sand beaches of Iceland?',
    },
    {
      image: Trending2,
      category: 'Places',
      location: 'Japan',
      title: 'A guide to Japan’s cherry blossom season',
    },
        {
      image: Trending4,
      category: 'Places',
      location: 'China',
      title: 'The best family vacations in the US',
    },
    {
      image: Trending5,
      category: 'Places',
      location: 'Iceland',
      title: 'Have you seen the black sand beaches of Iceland?',
    },
    {
      image: Trending6,
      category: 'Places',
      location: 'Ireland',
      title: 'A guide to Japan’s cherry blossom season',
    },
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 py-10">
      <h2 className="text-5xl sm:text-4xl md:text-5xl font-bold text-center mb-8 font-alumnisans uppercase tracking-widest">Trending Now</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {cards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

export default Trending;

  import React from "react";
  import BlogCard from "./BlogCard";
  import Romantice from "../assets/images/Romantic.png";
  import Travel from "../assets/images/Travel.png";
  import Profile from "../assets/images/Profile.png";
  import Romantic from "../assets/images/Romantic.png";
  import Booking from "../assets/images/Booking.png";
  const blogPosts = [
    {
      category: "Romantic",
      imageUrl: Romantice,
      date: "28 Aug 2024",
      comments: 20,
      title:
        "Experience Unbounded Love: Why Booking Romance Travel with a Specialist Makes Sense",
      description:
        "Ah, love. It’s a feeling that transcends borders, ignites passions, and whispers promises of joyful escapades! But sometimes, the everyday hustle can leave the spark a little dimmed.  What if  .",
      author: "Sophia Stephen",
      authorImageUrl: Profile,
    },
    {
      category: "Travel",
      imageUrl: Travel,
      date: "28 Aug 2024",
      comments: 20,
      title:
        "Experience Unbounded Love: Why Booking Romance Travel with a Specialist Makes Sense",
      description:
        "Ah, love. It’s a feeling that transcends borders, ignites passions, and whispers promises of joyful escapades! But sometimes, the everyday hustle can leave the spark a little dimmed.  What if  .",
      author: "Sophia Stephen",
      authorImageUrl: Profile,
    },
    {
      category: "Travel",
      imageUrl: Romantic,
      date: "28 Aug 2024",
      comments: 20,
      title:
        "Experience Unbounded Love: Why Booking Romance Travel with a Specialist Makes Sense",
      description:
        "Ah, love. It’s a feeling that transcends borders, ignites passions, and whispers promises of joyful escapades! But sometimes, the everyday hustle can leave the spark a little dimmed.  What if  .",
      author: "Sophia Stephen",
      authorImageUrl: Profile,
    },
  ];

  const BlogSection: React.FC = () => {
    return (
      <section className="bg-gray-100 py-0 px-4 md:px-16 bg-white">
        <div className="container mx-auto">
        <h2 className="mt-2 sm:mt-5 text-xl  lg:text-3xl font-bold font-akshar mb-2 sm:mb-4 uppercase text-center">
            Travel Blog
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {blogPosts.map((post, index) => (
              <BlogCard
                key={index}
                category={post.category}
                imageUrl={post.imageUrl}
                date={post.date}
                comments={post.comments}
                title={post.title}
                description={post.description}
                author={post.author}
                authorImageUrl={post.authorImageUrl}
              />
            ))}
          </div>
        </div>
      </section>
    );
  };

  export default BlogSection;

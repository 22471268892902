import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";
import Leftimage from "../assets/images/Weddingleft.png";

interface PlanningData {
  id: string;
  text: string;
  description: string;
  image?: string;
  buttonLink?: string;
  buttonText?: string;
}

const WeddingPreparationList: React.FC = () => {
  const [planning, setPlanning] = useState<PlanningData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningData = async () => {
      try {
        const response: ApiResponse<{ planning: PlanningData[] }> | null =
          await fetchData<{ planning: PlanningData[] }>({
            url: "pages/get",
            data: { pageId: 4 },
          });

        const planningData = response?.data?.planning;

        if (planningData && planningData.length > 0) {
          setPlanning(planningData[0]); // Assuming the first planning item is used
        } else {
          throw new Error("No planning data found.");
        }
      } catch (error) {
        setError("Failed to load planning data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlanningData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-600">{error}</p>;
  }

  return (
    <div className="py-10 px-4 sm:px-8 lg:px-20">
      <h3 className="text-xl sm:text-3xl lg:text-3xl font-semibold font-akshar  text-center uppercase">
        {planning?.text}
      </h3>
      <p className="lg:text-base sm:tracking-wide font-poppins mt-4 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
        {planning?.description}
      </p>
      {planning?.buttonLink && planning?.buttonText && (
        <div className="mt-6 text-center">
          <a
            href={planning.buttonLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-blue-600 hover:bg-blue-700 py-2 px-4 rounded"
          >
            {planning.buttonText}
          </a>
        </div>
      )}
    </div>
  );
};

export default WeddingPreparationList;

import React from "react";

const ServiceOverview: React.FC = () => {
  return (
    <div className="py-12 px-6 md:px-12 lg:px-24 bg-white text-center  font- ">
      <h2 className="text-xl sm:text-3xl lg:text-3xl font-semibold font-akshar text-center uppercase">
        Why Use the Best Denver Travel Agency?
      </h2>
      <div className="space-y-6 text-gray-800">
        <p className="lg:text-base sm:tracking-wide font-poppins mt-4 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
          We care about the smallest details when it comes to travel bookings. Regardless of whether you’re needing to find first-class flights, the perfect hotel accommodations, or the best touring ideas for your destination, Denver’s best travel agent, Travel N Relax, is ready to service you. In addition to booking your travel needs, we also provide you with requisite information and timely advice that may affect your trip and travel itinerary.
        </p>
        <p className="lg:text-base sm:tracking-wide font-poppins mt-4 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
          The best way to enjoy travel is to use the services of a professional travel consultant. And with decades of experience, Travel N Relax has maintained top ranking in terms of giving travel advice. Since we care about your safety and security, we give advice on local and international weather conditions as well as inform you if there are geopolitical issues that may affect your trip. All our clients have given testimonies to this effect.
        </p>
        <p className="lg:text-base sm:tracking-wide font-poppins mt-4 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
          Don’t take on the stress of planning your own vacation. Instead, call Travel N Relax and allow our trained professionals to use their contacts and connections to put together the vacation of your dreams. Contact Denver’s top travel agency today, and let us take care of all your travel details so that you can truly Travel N Relax!
        </p>
      </div>
      <button className="mt-10 px-8 py-2 border rounded border-black border-2 text-black uppercase font-medium hover:bg-black hover:text-white transition">
        Get in Touch
      </button>
    </div>
  );
};

export default ServiceOverview;

import React, { useState, useEffect } from "react";
import Rightimage from "../assets/images/Aboutus-img.svg";
import Leftimage from "../assets/images/Left-img.svg";
import { fetchData, ApiResponse } from "../services/api";

interface ApiData {
  intro: {
    id: string;
    text: string;
    description: string;
    image?: string;
    buttonLink?: string;
    buttonText?: string;
  }[];
}

const Aboutus: React.FC = () => {
  const [introData, setIntroData] = useState<{
    text: string;
    description: string;
    buttonLink?: string;
    buttonText?: string;
  } | null>(null);

  useEffect(() => {
    const fetchIntroData = async () => {
      try {
        const response: ApiResponse<ApiData> | null = await fetchData<ApiData>({
          url: "pages/get",
          data: { pageId: 1 },
        });

        const intro = response?.data?.intro;

        if (intro?.length) {
          const introItem = intro[0]; // Assuming the first item is needed
          setIntroData({
            text: introItem.text,
            description: introItem.description,
            buttonLink: introItem.buttonLink,
            buttonText: introItem.buttonText || "Learn More",
          });
        } else {
          console.warn("No intro data found in API response.");
        }
      } catch (error) {
        console.error("Error fetching intro data:", error);
      }
    };

    fetchIntroData();
  }, []);

  return (
    <div className="bg-white relative py-6 sm:py-16 px-3 sm:px-6 lg:px-8 text-center font-akshar">
      <div className="absolute right-0 top-2 sm:top-0 w-16 sm:w-auto">
        <img src={Rightimage} alt="Seamless Travel" className="object-fill" />
      </div>

      {introData ? (
        <>
          <h2 className="text-xl  lg:text-3xl font-akshar font-bold text-gray-900 tracking-wide">
            {introData.text}
          </h2>
          <p className="lg:text-base sm:tracking-wide font-poppins mt-4 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
            {introData.description}
          </p>
          {introData.buttonLink && (
            <div className="mt-6 sm:mt-8">
              <a
                href={introData.buttonLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-black border border-gray-600 py-2 px-4 sm:py-3 sm:px-8 text-sm sm:text-lg font-semibold rounded hover:bg-gray-100 inline-block"
              >
                {introData.buttonText}
              </a>
            </div>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}

      <div className="absolute left-0 bottom-2 sm:bottom-0 w-16 sm:w-36">
        <img src={Leftimage} alt="Seamless Travel" className="object-fill" />
      </div>
    </div>
  );
};

export default Aboutus;

import React from "react";
import image1 from "../assets/images/Visit-1.png";
import image2 from "../assets/images/Visit-2.png";
import image3 from "../assets/images/Visit-3.png";
import image4 from "../assets/images/Visit-4.png";

const BestPlacesToVisitSection: React.FC = () => {
  return (
    <div className="px-4 md:px-20">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mb-8">
        {/* First Column */}
        <div className="col-span-1 md:col-span-4 flex flex-col gap-y-4 justify-between">
          <div>
            <h3 className="text-xl sm:text-3xl lg:text-2xl font-bold font-akshar   uppercase">
              SOUTH AFRICA
            </h3>
            <p className="lg:text-base sm:tracking-wide font-poppins mt-2 sm:text-base md:text-lg text-gray-700 text-justify sm:text-left">
              Wake up, scent of coffee, first prickle of heat{" "}
              <br className="hidden md:block" />
              warming the ochre soil of the Tswalu Kalahari.
              <br className="hidden md:block" /> Before you, at first light?
              Possibly the greatest <br className="hidden md:block" />
              ‘Big Five’ safari you’ll ever embark on. March{" "}
              <br className="hidden md:block" /> is for South Africa.
            </p>
            <a
              href="#"
              className="text-black font-semibold underline hover:no-underline mt-2"
            >
              Read More
            </a>
          </div>
          <div className="flex-grow mt-4 md:mt-0">
            <img
              src={image1}
              alt="South Africa landscape"
              className="rounded-lg w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Second Column */}
        <div className="col-span-1 md:col-span-4 flex flex-col gap-y-4 justify-between">
          <div className="flex-grow">
            <img
              src={image2}
              alt="South Africa landscape"
              className="rounded-lg w-full h-full object-cover"
            />
          </div>
          <div className="flex-grow mt-4 md:mt-0">
            <img
              src={image3}
              alt="South Africa landscape"
              className="rounded-lg w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Third Column */}
        <div className="col-span-1 md:col-span-4 flex flex-col justify-between">
          <div className="flex-grow">
            <img
              src={image4}
              alt="South Africa landscape"
              className="rounded-lg w-full h-full object-cover mt-4 md:mt-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestPlacesToVisitSection;

import React from "react";
import Navbar from "../components/Navbar";
import background from "../assets/images/Background.png";

import Aboutus from "../components/Aboutus";
import Carousels from "../components/Carousels";
import CategoryHeading from "../components/CategoryHeading";
import CategoryNavigation from "../components/CategoryNavigation";
import CardGrid from "../components/CardGrid";
import CategoryButton from "../components/CategoryButton";
import Packages from "../components/Packages";
import About from "../components/About";
import TravelAdvisors from "../components/TravelAdvisors";
import TravelDirector from "../components/TravelDirector";
import SeamlessTravel from "../components/SeamlessTravel";
import AdventureTravel from "../components/AdventureTravel";
import CruiseTravel from "../components/CruiseTravel";
import IdealVacation from "../components/IdealVacation";
import Travelexperince from "../components/Travelexperince";

import BlogSection from "../components/BlogSection";
import Testimonials from "../components/Testimonials";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";

const Home: React.FC = () => {
  return (
    <div>
      <Navbar
        heading="20 YEARS OF MAKING TRAVEL DREAMS COME TRUE"
        subHeading="UNIFYING THE WORLD, ONE TRAVEL AT A TIME"
      />

      <div className="relative z-10">
        <Aboutus />
      </div>
      <div className="relative z-10">
        <Carousels />
      </div>

      {/* <div className="relative z-10 bg-[#F4F4F4]">
          <CategoryHeading />
        </div> */}
      {/* <div className="relative z-10 bg-[#F4F4F4]">
          <CategoryNavigation />
        </div> */}
      <div className="relative z-10 bg-[#F4F4F4]">
        <CardGrid />
      </div>

      <div className="relative z-10">
        <Packages />
      </div>
      <div className=" z-10 w-100">
        <About />
      </div>
      <div className=" z-10 ">
        <TravelAdvisors />
      </div>
      <div className="relative z-10 ">
        <TravelDirector />
      </div>
      <div className="relative z-10 mt-20">
        <SeamlessTravel />
      </div>
      <div className="relative z-10 ">
        <BlogSection />
      </div>
      <div className=" relative z-10 ">
        <Testimonials />
      </div>
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <div className="relative z-10">
        <Clients />
      </div>
      <div className="relative z-10">
        <Footer />
      </div>
    </div>
  );
};

export default Home;

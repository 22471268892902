import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import CardGrid from "../components/CardGrid";
import TravelAdvisors from "../components/TravelAdvisors";
import TravelDirector from "../components/TravelDirector";
import Testimonials from "../components/Testimonials";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import PlanningTrip from "../components/PlanningTrip";

const OurSpecialities: React.FC = () => {
  return <div className="">
  <Navbar

        subHeading=""
      />
      <CardGrid/>
      <TravelAdvisors/>
      <TravelDirector/>
      <div className="mt-20"><Testimonials/></div>
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
  </div>;
};

export default OurSpecialities;

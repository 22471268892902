import React from "react";
import background from "../assets/images/Banner-Family.png";

const HeroSection: React.FC = () => {
  return (
    <div className="mt-10">
      <div
        className="relative h-[600px] sm:h-screen bg-cover bg-center flex items-center justify-center"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className=" border-2 border-black w-[90%] sm:w-[80%] h-[80%] sm:h-[70%] p-4 sm:p-8 lg:p-16 text-black overflow-y-auto">
          <h2 className="text-xl mt-3  lg:text-3xl font-semibold font-akshar text-center font-semibold uppercase font-alumnisans">
            Top-Rated Travel Agent in Aurora – Creating Family Traditions For Over 19 Years
          </h2>
          <p className="lg:text-base sm:tracking-wide font-poppins mt-5 sm:text-base md:text-lg text-gray-700 text-justify sm:text-left">
            At Travel N Relax, we offer expert travel guidance and destination recommendations. We customize your vacation according to your needs and desires and are available to handle all of your travel needs. Our customer support team is always ready to assist with regular and emergency calls as well as contingency plans for your travels.
          </p>
          <p className="lg:text-base sm:tracking-wide font-poppins sm:text-base md:text-lg text-gray-700 text-justify sm:text-left">
            Our expertise covers a broad variety of travel services including family travel, luxury travel, group travel, all-inclusive vacations, cruises, Disney vacations, and more. With Travel N Relax, you gain access to exclusive opportunities, networks, and connections that are difficult to find on your own. We are ranked among the best travel agencies in Denver, and we’re happy to connect you with invaluable local resources.
          </p>
          <p className="lg:text-base sm:tracking-wide font-poppins sm:text-base md:text-lg text-gray-700 text-justify sm:text-left">
            We understand the economic concerns of our clients, which is why we offer the best vacation value for your money. Our pricing models fit comfortably into your budget. Whether traveling domestically or globally, our travel advisors will create a value-filled vacation that meets all your travel desires.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
      
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { fetchData, ApiResponse } from "../services/api";

// Define the required interfaces
interface PackageSlider {
  packageId: string;
  title: string;
  subText: string;
  image: string;
  days: string;
  nights: string;
  facilities: string;
  description: string;
  buttonText: string;
  buttonLink: string;
}

interface PackagesIntro {
  id: string;
  text: string;
  description: string;
  image: string;
  buttonLink: string;
  buttonText: string;
}

const Packages: React.FC = () => {
  const [packages, setPackages] = useState<PackageSlider[]>([]);
  const [intro, setIntro] = useState<PackagesIntro | null>(null);
  const [currentCard, setCurrentCard] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number | null>(null); // Added for hover
  const [loading, setLoading] = useState(true);

  const isSmallScreen = useMediaQuery({ query: "(max-width: 640px)" });

  useEffect(() => {
    const fetchPackagesData = async () => {
      try {
        const response: ApiResponse<{
          package_sliders: PackageSlider[];
          packages_intro: PackagesIntro[];
        }> | null = await fetchData<{
          package_sliders: PackageSlider[];
          packages_intro: PackagesIntro[];
        }>({
          url: "pages/get",
          data: { pageId: 1 },
        });

        if (response?.data?.package_sliders) {
          setPackages(response.data.package_sliders);
        }

        if (response?.data?.packages_intro?.length) {
          setIntro(response.data.packages_intro[0]);
        } else {
          console.warn("No packages_intro found in API response.");
        }
      } catch (error) {
        console.error("Error fetching packages data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPackagesData();
  }, []);

  const handleNextCard = () => {
    setCurrentCard((prevIndex) => {
      const nextIndex = prevIndex + 2; // Adjust based on how many cards you display at a time
      return nextIndex < packages.length ? nextIndex : 0; // Reset to 0 if at the end
    });
  };

  const handlePrevCard = () => {
    setCurrentCard((prevIndex) => {
      const prevIndexAdjusted = prevIndex - 2; // Adjust based on how many cards you display at a time
      return prevIndexAdjusted >= 0
        ? prevIndexAdjusted
        : Math.max(0, packages.length - 2); // Loop to the end
    });
  };

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="relative bg-gradient-to-b from-[#01316E] via-[#737373] to-[#01316E] text-white p-5 sm:p-10 md:p-10 lg:h-[950px]">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center space-y-8 lg:space-y-0 lg:space-x-8">
        {intro && (
          <div className="w-full lg:w-2/2 space-y-4 px-3 text-center lg:text-left hidden sm:block">
            <h1 className="text-xl sm:text-3xl md:text-5xl font-semibold leading-tight tracking-wide font-mplus2 whitespace-nowrap">
              Explore the World with Our <br /> Exclusive Handpicked Tours.
            </h1>
            <p className="text-xs sm:text-base mt-2 sm:mt-5 leading-relaxed font-poppins">
              {intro.description
                .split("\r\n")
                .map((line: string, idx: number) => (
                  <React.Fragment key={idx}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          </div>
        )}

        <div className="w-full lg:w-2/2 relative h-[470px] sm:h-[900px] flex items-center justify-center">
          <button
            onClick={handlePrevCard}
            className="absolute -left-4 sm:left-4 top-1/2 transform -translate-y-1/2 border-2 border-white text-white rounded-full w-8 sm:w-10 h-8 sm:h-10 flex justify-center items-center z-10"
          >
            <IoIosArrowBack size={!isSmallScreen ? 28 : 20} />
          </button>

          <div className="flex overflow-hidden space-x-4">
            {packages.slice(currentCard, currentCard + 2).map((pkg, index) => (
              <div
                key={pkg.packageId}
                className={`relative rounded-lg overflow-hidden shadow-lg transition-all duration-300 transform ${
                  activeIndex === index
                    ? "scale-105 brightness-[80%]"
                    : "hover:scale-105"
                } w-[300px] sm:w-[200px] md:w-[260px] lg:w-[300px]
`}
                style={{
                  height: isSmallScreen ? "400px" : "630px", // Conditional height
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {/* Image with fallback */}
                <img
                  src={pkg.image}
                  onError={
                    (e) => (e.currentTarget.src = "/fallback-image.jpg") // Fallback image path
                  }
                  alt={pkg.title}
                  className="w-full h-full object-cover"
                />

                <div className="absolute top-0 left-0 w-full h-full rounded bg-gradient-to-t from-black to-transparent opacity-50"></div>
                <div className="absolute bottom-1 leading-8 p-2 sm:p-4 lg:p-8 text-center lg:text-left">
                  <div className="font-mplus2 text-xs sm:text-sm lg:text-base font-semibold tracking-widest mt-8 sm:mt-12">
                    {pkg.title}
                  </div>
                  <div className="font-alumnisans text-base tracking-wide sm:text-sm lg:text-base">
                    {pkg.subText}
                  </div>
                  {activeIndex === index && (
                    <div className="font-akshar text-xs sm:text-sm lg:text-base leading-6 text-[#E6D783] mt-1">
                      {pkg.facilities || "No facilities listed"}
                    </div>
                  )}
                  <div className="flex items-center justify-center sm:justify-start">
                    {pkg.buttonText && pkg.buttonLink && (
                      <a
                        href={pkg.buttonLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white border-2 border-white py-1 px-2 text-xs sm:text-sm lg:text-lg font-semibold rounded"
                      >
                        {pkg.buttonText}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <button
            onClick={handleNextCard}
            className="absolute -right-4 sm:right-4 top-1/2 transform -translate-y-1/2 border-2 border-white text-white rounded-full w-8 sm:w-10 h-8 sm:h-10 flex justify-center items-center z-10"
          >
            <IoIosArrowForward size={!isSmallScreen ? 28 : 20} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Packages;

import React from "react";
import { useNavigate } from "react-router-dom";
import JanuaryImage from "../assets/images/Jan.jpg";
import FebruaryImage from "../assets/images/Feb.jpg";
import MarchImage from "../assets/images/Mar.jpg";
import AprilImage from "../assets/images/Apr.jpg";
import MayImage from "../assets/images/May.jpg";
import JuneImage from "../assets/images/Jun.jpg";
import JulyImage from "../assets/images/Jul.jpg";
import AugustImage from "../assets/images/Aug.jpg";
import SeptemberImage from "../assets/images/Sept.jpg";
import OctoberImage from "../assets/images/Oct.jpg";
import NovemberImage from "../assets/images/Nov.jpg";
import DecemberImage from "../assets/images/Dec.jpg";

const monthData = [
  { name: "January", image: JanuaryImage, path: "/monthly/january" },
  { name: "February", image: FebruaryImage, path: "/monthly/february" },
  { name: "March", image: MarchImage, path: "/monthly/march" },
  { name: "April", image: AprilImage, path: "/monthly/april" },
  { name: "May", image: MayImage, path: "/monthly/may" },
  { name: "June", image: JuneImage, path: "/monthly/june" },
  { name: "July", image: JulyImage, path: "/monthly/july" },
  { name: "August", image: AugustImage, path: "/monthly/august" },
  { name: "September", image: SeptemberImage, path: "/monthly/september" },
  { name: "October", image: OctoberImage, path: "/monthly/october" },
  { name: "November", image: NovemberImage, path: "/monthly/november" },
  { name: "December", image: DecemberImage, path: "/monthly/december" },
];

const MonthGallery = () => {
  const navigate = useNavigate();

  const handleMonthClick = (path: string) => {
    navigate(path);
  };

  return (
    <div className="container mx-auto my-10 px-4 md:px-28 mt-14">
      <h2 className="mt-2 tracking-wide sm:mt-5 text-xl  lg:text-3xl font-bold font-akshar mb-2 sm:mb-4 uppercase text-center">
        Month
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 sm:gap-6">
        {monthData.map((month, index) => (
          <div
            key={index}
            onClick={() => handleMonthClick(month.path)}
            className="cursor-pointer transition-transform duration-300 hover:scale-105 relative h-44 sm:h-64 rounded-lg overflow-hidden bg-cover bg-center shadow-md"
            style={{
              backgroundImage: `url(${month.image})`,
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
              <h5 className="text-white font-mplus2 text-sm sm:text-lg font-bold uppercase tracking-widest">
                {month.name}
              </h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthGallery;

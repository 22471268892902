import React from "react";

const PrincipleCard: React.FC = () => {
  return (
    <div className="flex flex-col sm:flex-row p-4 gap-6 sm:gap-10  justify-center mt-10 sm:mt-20">
      <div className="bg-white shadow rounded-lg p-6 sm:p-10 text-center border border-white">
        <h2 className="text-3xl sm:text-2xl tracking-wide font-semibold text-[#001a78] mb-4 uppercase font-alumnisans">
          Honor
        </h2>
        <p className="text-gray-700 font-poppins tracking-wide leading-0 sm:leading-7 text-sm lg:text-base   sm:text-lg">
          Cultivate respectful, honest <br /> relationships between travelers,
          <br /> communities, and cultures.
        </p>
      </div>
      <div className="bg-white shadow rounded-lg p-6 sm:p-10 text-center border border-white">
      <h2 className="text-3xl sm:text-2xl tracking-wide font-semibold text-[#001a78] mb-4 uppercase font-alumnisans">
          Diligence
        </h2>
        <p className="text-gray-700 font-poppins tracking-wide leading-0 sm:leading-7 text-sm lg:text-base  sm:text-lg">
          Provide meticulous planning and <br /> support to travelers before,
          <br /> during, and after their trip.
        </p>
      </div>
      <div className="bg-white shadow rounded-lg p-6 sm:p-10 text-center border border-white">
      <h2 className="text-3xl sm:text-2xl tracking-wide font-semibold text-[#001a78] mb-4 uppercase font-alumnisans">
          Legacy
        </h2>
        <p className="text-gray-700 font-poppins tracking-wide leading-0 sm:leading-7 text-sm lg:text-base  sm:text-lg">
          Create extremely personal, one-of-a- <br /> kind experiences that are
          <br /> shared for generations.
        </p>
      </div>
    </div>
  );
};

export default PrincipleCard;

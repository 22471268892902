import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { IoIosGlobe } from "react-icons/io";
import Footerlogo from "../assets/images/Footer-Logo.png";
import Trip1 from "../assets/images/Footerimg.png";
import Trip2 from "../assets/images/Trip2.png";
import Trip3 from "../assets/images/Trip3.png";
import Trip4 from "../assets/images/Trip4.png";
import Trip5 from "../assets/images/Trip5.png";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-white py-6 px-4 md:py-12 md:px-6">
      <div className="container grid grid-cols-1 md:grid-cols-12 gap-6 md:px-20">
        {/* Logo and Intro Section */}
        <div className="col-span-12 md:col-span-3 space-y-4 font-akshar text-center md:text-left">
          <img
            src={Footerlogo}
            alt="Logo"
            className="h-16 md:h-20 lg:h-28 w-auto mx-auto md:mx-0"
          />
          <p className="text-xs sm:text-sm font-normal font-poppins leading-loose tracking-widest text-white">
            Welcome To Travel N Relax, Your Premier Denver Travel Agency
            Specializing In Creating Fully Customized Vacations That Turn Your
            Travel Dreams Into Reality. With Over 19 Years Of Expertise.
          </p>
        </div>

        {/* Travel Links Section */}
        <div className="space-y-4 col-span-12 md:col-span-2 text-center md:text-left">
          <h3 className="font-semibold font-mplus2 tracking-wide text-base md:text-lg uppercase  text-white opacity-95 tracking-widest">
            Travel
          </h3>
          <ul className="text-xs  sm:text-sm space-y-1">
            {[
              "Family Travel",
              "Group Travel",
              "All Inclusive Vacations",
              "Ocean Cruise",
              "River Cruise",
              "Anniversary",
              "Honeymoon Gateways",
              "Destination Weddings",
              "Disney Vacations",
            ].map((link, index) => (
              <li key={index}>
                <Link
                  to={`/${link.toLowerCase().replace(/ /g, "-")}`}
                  className="font-akshar text-white opacity-70 tracking-widest"
                >
                  {link}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* About Us Links Section */}
        <div className="space-y-4 col-span-12 md:col-span-2 text-center md:text-left">
          <h3 className="font-semibold font-mplus2 text-base md:text-lg uppercase text-white opacity-95 tracking-wide">
            About Us
          </h3>
          <ul className="text-xs font-anekbangla sm:text-sm space-y-1  text-white opacity-70 tracking-widest">
            {[
              "Testimonials",
              "Our Team",
              "Blog",
              "Contact",
              "Terms Of Use",
              "Privacy Policy",
            ].map((link, index) => (
              <li key={index}>
                <Link to={`/${link.toLowerCase().replace(/ /g, "-")}`}>
                  {link}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Help & FAQ Section */}
        <div className="space-y-4 col-span-12 md:col-span-2 text-center md:text-left">
          <h3 className="font-semibold tracking-wide-mplus2 text-base md:text-lg uppercase text-white opacity-95 tracking-wide">
            Help & FAQ's
          </h3>
          <ul className="text-xs sm:text-sm space-y-1 font-akshar text-white opacity-70 tracking-widest">
            {["Customer Service", "Contact Us", "Accessibility"].map(
              (link, index) => (
                <li key={index}>
                  <Link to={`/${link.toLowerCase().replace(/ /g, "-")}`}>
                    {link}
                  </Link>
                </li>
              )
            )}
          </ul>
          <div className="space-y-2 mt-4 md:mt-10">
            <h3 className="font-semibold tracking-wide-mplus2  text-base md:text-lg uppercase text-white opacity-95 tracking-widest">
              Contact Us
            </h3>
            <p className="text-xs sm:text-sm font-akshar mt-3 text-white opacity-70">
              Phone: <a href="tel:303-317-6945">303-317-6945</a>
            </p>
            <p className="text-xs sm:text-sm font-akshar text-white opacity-70">
              Email:{" "}
              <a href="mailto:info@Travelnrelax.com">info@Travelnrelax.com</a>
            </p>
          </div>
        </div>

        {/* Recent Trips Section */}
        <div className="col-span-12 md:col-span-3 text-center md:text-left">
          <h3 className="font-semibold tracking-wide-mplus2  text-base md:text-lg uppercase text-white opacity-90 tracking-wide">
            Recent Trips
          </h3>
          <div className="grid grid-cols-3 gap-3 mt-5">
            {[Trip1, Trip2, Trip3, Trip4, Trip5, Trip2].map((trip, index) => (
              <img
                key={index}
                src={trip}
                alt={`Trip ${index + 1}`}
                className="h-16 sm:h-20 w-full object-fill"
              />
            ))}
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="container mx-auto w-full md:w-[90%] mt-6 md:mt-12 border-t-2 border-gray-700 pt-4 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
        <div className="flex items-center space-x-2 text-xs sm:text-sm">
          <label
            htmlFor="region"
            className="text-white font-akshar opacity-70 tracking-widest"
          >
            Choose Region:
          </label>
          <select
            id="region"
            className="bg-transparent border border-gray-400 py-2 px-4 text-white"
          >
            <option className="font-akshar opacity-70 tracking-widest">
              United States
            </option>
            <option className="font-akshar opacity-70 tracking-widest">
              Canada
            </option>
          </select>
        </div>
        <div className="flex space-x-4">
          <FaFacebookF className="h-5 sm:h-6 w-5 sm:w-6" />
          <FaTwitter className="h-5 sm:h-6 w-5 sm:w-6" />
          <FaLinkedinIn className="h-5 sm:h-6 w-5 sm:w-6" />
          <FaInstagram className="h-5 sm:h-6 w-5 sm:w-6" />
          <FaYoutube className="h-5 sm:h-6 w-5 sm:w-6" />
          <IoIosGlobe className="h-5 sm:h-6 w-5 sm:w-6" />
        </div>
      </div>

      {/* Copyright Section */}
      <div className="flex flex-col items-center text-center mt-8 md:mt-12 w-full">
        <div className="opacity-70 text-xs sm:text-sm tracking-widest font-akshar">
          Copyright©2024TRAVEL’N RELAX USA, Inc. All rights reserved.
        </div>
        <div className="opacity-70 text-xs sm:text-sm tracking-widest font-akshar flex space-x-4">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-use">Terms & Conditions</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from "react";
import CarouselCard from "./CarouselCard";
import CarouselNavigation from "./CarouselNavigation";

const testimonials = [
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
  {
    text: "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    author: "Forbes",
  },
];

const TestimonialCarousel: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <div className="relative flex flex-col items-center justify-center  overflow-hidden mb-7">
      {/* Testimonial content */}
      <div
        className="flex transition-transform ease-in-out duration-500 "
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {testimonials.map((testimonial, index) => (
          <div className="flex-shrink-0 w-full sm:w-1/3 " key={index}>
            <CarouselCard text={testimonial.text} author={testimonial.author} />
          </div>
        ))}
      </div>

      {/* Navigation buttons for mobile - below the card */}
      <div className="flex space-x-4 mt-4 sm:hidden">
        <CarouselNavigation direction="left" onClick={prevSlide} />
        <CarouselNavigation direction="right" onClick={nextSlide} />
      </div>

      {/* Navigation buttons for larger screens - on the sides */}
      <CarouselNavigation
        direction="left"
        onClick={prevSlide}
        className="hidden sm:flex absolute left-4 sm:left-10 transform -translate-y-1/2 top-1/2"
      />
      <CarouselNavigation
        direction="right"
        onClick={nextSlide}
        className="hidden sm:flex absolute right-4 sm:right-10 transform -translate-y-1/2 top-1/2"
      />
    </div>
  );
};

export default TestimonialCarousel;

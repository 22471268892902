import React, { useState, useEffect } from "react";
import { fetchData, ApiResponse } from "../services/api";

interface AboutData {
  id: string;
  text: string;
  description: string;
  image: string;
  buttonLink: string;
  buttonText: string;
}

const About: React.FC = () => {
  const [aboutData, setAboutData] = useState<AboutData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response: ApiResponse<{ about: AboutData[] }> | null =
          await fetchData<{ about: AboutData[] }>({
            url: "pages/get",
            data: { pageId: 1 },
          });

        if (response?.data?.about?.length) {
          setAboutData(response.data.about[0]); // Assuming the first object is the one to display
        } else {
          console.warn("No about data found in API response.");
        }
      } catch (error) {
        console.error("Error fetching about data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!aboutData) {
    return <div>No About Data Available</div>;
  }

  return (
    <div className="flex flex-wrap -mx-4 bg-[#FBF6F6]">
      {/* Image section: equivalent to col-4 */}
      <div className="w-full h-full md:w-1/3 px-4">
        <img
          src={aboutData.image} // Dynamically loaded image
          alt="About Section"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Text content section */}
      <div className="w-full md:w-2/3 p-10">
        <h2 className="text-xl lg:text-3xl font-akshar font-bold mb-4  text-center md:text-left">
          {aboutData.text}
        </h2>
        <p className="lg:text-base sm:tracking-wide mb-6 font-poppins font-akshar text-justify sm:text-left">
          {aboutData.description}
        </p>
        {aboutData.buttonText && (
          <div className="flex justify-center lg:justify-start">
            <a
              href={aboutData.buttonLink || "#"}
              className="mt-4 py-2 px-4 lg:px-6 border border-black text-black font-semibold border-2 rounded-md hover:bg-gray-700 hover:text-white transition"
            >
              {aboutData.buttonText}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default About;

import React from "react";
import Right from "../assets/images/Right-TravelMonth.svg";
import Left from "../assets/images/Left-TravelMonth.svg";

const TravelMonthSection: React.FC = () => {
  return (
    <div className="relative">
      <div className="py-16 px-4 md:px-10 lg:px-20 text-center bg-white">
        <h2 className="text-xl sm:text-3xl lg:text-3xl font-semibold font-akshar  text-center uppercase">
          WHERE TO TRAVEL IN MARCH
        </h2>
        <p className="lg:text-base sm:tracking-wide font-poppins mt-3 sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
          Spring is on the horizon – and the days are getting warmer. And with
          the Easter and spring break approaching, now is a perfect time to plan
          a luxury
        </p>
        <p className="lg:text-base sm:tracking-wide font-poppins sm:text-base md:text-lg text-gray-700 text-justify sm:text-center">
          From a magical Mexican escape to paradise in Sri Lanka. The world is
          yours. We’ve hand-picked the ideas below to get your mind racing about
          where <br /> to travel in March. As ever, our luxury travel experts
          are on hand to give you detailed guidance and expert suggestions in
          choosing your trip.
        </p>
        <button className="border border-black text-black px-6 py-2 mt-2 font-semibold hover:bg-gray-200 transition duration-300 ease-in-out">
          GET IN TOUCH
        </button>
      </div>
      <div className="absolute top-0 right-0  ">
        <img src={Right} alt="Rob Hale" className=" object-fill" />
      </div>

      <div className="absolute bottom-0 left-0  ">
        <img src={Left} alt="Rob Hale" className="h-80 w-full  object-fill" />
      </div>
    </div>
  );
};

export default TravelMonthSection;

import React from "react";
import contactlogo from "../assets/images/contact-logo.png";
import Right from "../assets/images/Rbottom-Experience.svg";
import Left from "../assets/images/Lbottom-Experience.svg";

const ContactSection: React.FC = () => {
  return (
    <div className="relative flex flex-col  items-center justify-center">
      <div className="mt-4">
        <img
          src={contactlogo}
          alt="Logo"
          className="h-20 md:h-28 w-auto mx-auto md:mx-0"
        />
      </div>
      <div className="text-center leading-6 mb-5 mt-5">
        <div className="text-xs font-semibold uppercase">TRAVEL N RELAX</div>
        <div className="text-xs uppercase text-black opacity-[95%]">
          15385 E 7th Cir, Denver, Colorado, CO 80011
        </div>
        <div className="text-xs uppercase text-black opacity-[95%]">
          (303) 317-6945
        </div>
        <div className="text-xs uppercase text-black opacity-[95%]">
          info@travelnrelax.com 
        </div>
      </div>
      <div className="absolute bottom-0 right-0  ">
        <img src={Right} alt="Rob Hale" className=" object-fill" />
      </div>
      <div className="absolute bottom-0 left-0  ">
        <img src={Left} alt="Rob Hale" className="h-80 w-full  object-fill" />
      </div>
    </div>
  );
};

export default ContactSection;

import React, { useEffect, useState } from "react";
import Directorleft from "../assets/images/DirectorLeft.svg";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { fetchData } from "../services/api";
import { Link } from "react-router-dom";

interface AdvisorData {
  id: string;
  name: string;
  image: string;
  designation: string;
  description: string;
}

const TravelDirector: React.FC = () => {
  const [lisaHaleData, setLisaHaleData] = useState<AdvisorData | null>(null);

  useEffect(() => {
    const fetchLisaHaleData = async () => {
      try {
        const response = await fetchData<{ advisors: AdvisorData[] }>({
          url: "pages/get",
          data: { pageId: 1 },
        });

        const advisors = response?.data?.advisors;

        if (advisors?.length) {
          const lisaHale = advisors.find(
            (advisor) => advisor.name.toLowerCase() === "lisa hale"
          );

          if (lisaHale) {
            setLisaHaleData(lisaHale);
          } else {
            console.warn("Lisa Hale's data not found.");
          }
        } else {
          console.warn("No advisors data found in API response.");
        }
      } catch (error) {
        console.error("Error fetching Lisa Hale's data:", error);
      }
    };

    fetchLisaHaleData();
  }, []);

  return (
    <div className="flex flex-col lg:flex-row items-center lg:items-start">
      <div className="w-full lg:w-52 h-40 lg:h-[250px] top-0 left-0 mb-4 lg:mb-0 lg:block hidden">
        <img
          src={Directorleft}
          alt="Director Left"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="w-full lg:w-screen relative px-4 lg:px-0">
        <div className="flex flex-col lg:flex-row gap-6 lg:gap-10 items-center lg:items-start w-full lg:w-[90%]">
          <div className="order-1 lg:order-2 w-40 h-40 lg:w-[300px] lg:h-[300px]">
            <img
              src={lisaHaleData?.image || ""}
              alt={lisaHaleData?.name || "Lisa Hale"}
              className="mx-auto h-full w-full object-fill"
            />
          </div>
          <div className="flex-1 bg-gray-100 rounded-lg shadow-lg p-4 lg:p-6 h-auto lg:h-[300px] order-2 lg:order-1 text-center md:text-left">
            <h3 className="text-2xl font-semibold text-gray-800 font-mplus2">
              {lisaHaleData?.name || "Advisor Name"}
            </h3>

            <div className="flex flex-col lg:flex-row justify-center lg:justify-between border-b-2 border-gray-500 py-2 w-full">
              <p className="text-sm font-bold font-akshar mb-2 lg:mb-0 text-center lg:text-left">
                {lisaHaleData?.designation || "Advisor Designation"}
              </p>

              <div className="flex space-x-4 mt-2 lg:mt-0 justify-center lg:justify-start">
                <a
                  href="#"
                  className="text-black hover:text-gray-800 w-6 h-6 bg-[#BBBBBB] rounded-full p-1 text-sm"
                >
                  <FaFacebookF />
                </a>
                <a
                  href="#"
                  className="text-black hover:text-gray-800 w-6 h-6 bg-[#BBBBBB] rounded-full p-1 text-sm"
                >
                  <FaInstagram />
                </a>
                <a
                  href="#"
                  className="text-black hover:text-gray-800 w-6 h-6 bg-[#BBBBBB] rounded-full p-1 text-sm"
                >
                  <FaLinkedinIn />
                </a>
                <a
                  href="#"
                  className="text-black hover:text-gray-800 w-6 h-6 bg-[#BBBBBB] rounded-full p-1 text-sm"
                >
                  <FaTwitter />
                </a>
              </div>
            </div>

            <p className="text-sm lg:text-base font-poppins text-black font-light mt-4  tracking-wide text-justify sm:text-left">
              {lisaHaleData?.description ||
                "Lisa Hale's detailed description will appear here."}
            </p>

            <div className="flex justify-center lg:justify-start">
              <button className="mt-5 py-2 px-4 lg:px-6 border border-black text-black font-semibold border-2 rounded-md hover:bg-gray-700 hover:text-white transition">
                <Link to="/aboutus">About</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelDirector;

import React from "react";
import Navbar from "../components/Navbar";
import DisneyTheme from "../components/DisneyTheme";
import DisneyBanner from "../components/DisneyBanner";
import DisneyVacationPreperation from "../components/DisneyVacationPreperation";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import { fetchData } from "../services/api";
import { useState, useEffect } from "react";

const DisneyVacation: React.FC = () => {
  const [planningTripData, setPlanningTripData] = useState<any>({
    headingplanning: "",
    paragraph: "",
  });

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningTripData = async () => {
      try {
        const response = await fetchData<{
          whereTo: { headingplanning: string; paragraph: string }[];
        }>({
          url: "pages/get",
          data: { pageId: 10 },
        });

        const whereToData = response?.data?.whereTo?.[0];
        console.log("API Response:", response?.data);
        console.log(whereToData);

        if (whereToData) {
          setPlanningTripData(whereToData);
        } else {
          throw new Error("No whereTo data found.");
        }
      } catch (error) {
        console.error(error);
        setError("Failed to load Planning Trip data.");
      }
    };

    fetchPlanningTripData();
  }, []);
  console.log(planningTripData);
  return (
    <div>
      <Navbar />
      <DisneyTheme />
      <DisneyBanner />
      <DisneyVacationPreperation />
      {error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <PlanningTrip
          headingplanning={planningTripData?.text || "Default Heading"}
          paragraph={planningTripData?.description}
        />
      )}
        <Clients/>
        <Footer/>

    </div>
  );
};

export default DisneyVacation;

import React from "react";
import Navbar from "../components/Navbar";
import AccessibilityContent from "../components/Accessibility";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";

const Accessibility: React.FC = () => {
  return (
    <div>
      <Navbar />
      <AccessibilityContent />
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default Accessibility;

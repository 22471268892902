import React, { useEffect, useState } from "react";
import { fetchData, ApiResponse } from "../services/api";
import Right from "../assets/images/Right-Destination.svg";
import Left from "../assets/images/left-Destination.svg";

const TravelOption: React.FC = () => {
  const [destinations, setDestinations] = useState<
    { name: string; image: string }[]
  >([]);
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response: ApiResponse<{
          destinations: { name: string; image: string }[];
          image: { image: string }[];
        }> | null = await fetchData({
          url: "pages/get",
          data: { pageId: 17 },
        });

        if (response?.data) {
          setDestinations(response.data.destinations || []);
          setMainImage(response.data.image?.[0]?.image || null);
        } else {
          throw new Error("No data found.");
        }
      } catch (err) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPageData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-600">{error}</p>;
  }

  return (
    <div className="relative">
      <div className="md:px-20 py-10 sm:py-10 grid grid-cols-12 gap-8">
        {/* Text Section */}
        <div className="col-span-12 md:col-span-5 flex flex-col items-center">
          <h2 className="font-bold font-akshar uppercase tracking-wide text-lg sm:text-xl text-[#01316E] text-center">
            Explore Our Destinations
          </h2>
          <ul className="space-y-2 font-mplus2 sm:space-y-6 mt-6 sm:mt-10">
            {destinations.map((destination, index) => (
              <li
                key={index}
                className="font-semibold font-akshar tracking-widest text-base sm:text-lg text-center"
              >
                {destination.name}
              </li>
            ))}
          </ul>
        </div>

        {/* Image Section */}
        <div className="col-span-12 md:col-span-7 flex justify-center">
          {mainImage && (
            <img
              src={mainImage}
              alt="Travel Destination"
              className="w-full rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:brightness-110"
            />
          )}
        </div>
      </div>

      {/* Right Decorative Image */}
      <div className="absolute top-0 right-0 hidden lg:block">
        <img src={Right} alt="Decorative Right" className="object-fill" />
      </div>

      {/* Left Decorative Image */}
      <div className="absolute bottom-0 left-0 hidden lg:block">
        <img
          src={Left}
          alt="Decorative Left"
          className="h-40 sm:h-80 w-full object-fill"
        />
      </div>
    </div>
  );
};

export default TravelOption;

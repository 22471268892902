import React from "react";
import Header from "../components/Header";
import TestimonialView from "../components/TestimonialView";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";

const TestimonialsCards: React.FC = () => {
  return (
    <div>
      <Header />
      <TestimonialView />
      <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients />
      <Footer />
    </div>
  );
};

export default TestimonialsCards;

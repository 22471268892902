import React from "react";

const CustomerServiceContent: React.FC = () => {
  return (
    <div className="min-h-screen  py-16 px-4 sm:px-8 lg:px-16">
      <section className="max-w-7xl mx-auto text-center">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl mb-8 font-bold text-gray-800 uppercase tracking-widest">
          Customer Service
        </h1>
        <div className="space-y-8 text-gray-700">
          <p className="text-lg sm:text-xl font-light leading-relaxed max-w-3xl mx-auto">
            At Travel N Relax, our priority is ensuring you have a seamless and
            enjoyable travel experience. Our Customer Service team is here to
            assist with any inquiries, resolve issues, and provide support for
            your travel needs.
          </p>

          <div>
            <h2 className="text-xl sm:text-2xl font-semibold tracking-widest mb-4 text-gray-800">
              How to Reach Us
            </h2>
            <ul className="list-disc mx-auto space-y-3">
              <div className="text-lg font-medium">
                Phone:
                <a
                  href="tel:303-317-6945"
                  className="text-blue-600 underline hover:text-blue-700"
                >
                  303-317-6945
                </a>
              </div>
              <div className="text-lg font-medium">
                Email:{" "}
                <a
                  href="mailto:info@Travelnrelax.com"
                  className="text-blue-600 underline hover:text-blue-700"
                >
                  info@Travelnrelax.com
                </a>
              </div>
              <div className="text-lg">
                Working Hours: Monday - Friday, 9:00 AM - 5:00 PM (MST)
              </div>
            </ul>
          </div>

          <div>
            <h2 className="text-xl sm:text-2xl font-semibold tracking-widest mb-4 text-gray-800">
              FAQs
            </h2>
            <p className="text-lg font-light leading-relaxed max-w-3xl mx-auto">
              Visit our
              <a
                href="/faqs"
                className="text-blue-600 underline hover:text-blue-700"
              >
                FAQ page
              </a>
              to find answers to common questions about bookings, cancellations,
              and more.
            </p>
          </div>

          <div>
            <h2 className="text-xl sm:text-2xl font-semibold tracking-widest mb-4 text-gray-800">
              Feedback
            </h2>
            <p className="text-lg font-light leading-relaxed max-w-3xl mx-auto">
              Your feedback is valuable to us. If you have suggestions or
              concerns, please email us or call our support team. We’re
              committed to improving our services for a better travel
              experience.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CustomerServiceContent;

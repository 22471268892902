import React from "react";
import BlogDetails from "../components/BlogDetails";
import Navbar from "../components/Navbar";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";


const BlogDetailing: React.FC = () => {
  return (
  <div>
    <Navbar/>
    <BlogDetails/>
    <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients/>
     <Footer/>

  </div>
  );
};

export default BlogDetailing;

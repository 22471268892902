import React from "react";

const AccessibilityContent: React.FC = () => {
  return (
    <div className="min-h-screen p-12 mt-10">
      <section className="max-w-5xl mx-auto">
        <h1 className="text-3xl uppercase font-bold font-alumnisans tracking-wide sm:text-4xl text-center text-gray-800 mb-10">
          Accessibility
        </h1>
        <div className="space-y-6 text-gray-700">
          <p className="leading-relaxed font-light text-lg font-akshar tracking-wide text-center sm:text-left">
            At Travel N Relax, we are committed to ensuring our services and
            website are accessible to everyone, including individuals with
            disabilities.
          </p>
          <div>
            <h2 className="text-xl font-semibold mb-3 font-alumnisans tracking-wide text-center sm:text-left">
              Our Accessibility Commitment
            </h2>
            <p className="leading-relaxed font-light text-lg font-akshar tracking-wide text-center sm:text-left">
              We strive to comply with all applicable accessibility standards
              and continuously improve our website to enhance usability for
              people with disabilities.
            </p>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-3 font-alumnisans tracking-wide text-center sm:text-left">
              Accessibility Features
            </h2>
            <ul className="list-disc ml-6 space-y-2 font-akshar tracking-wide text-center sm:text-left">
              <li>Keyboard navigation for all website </li>
              <li>Text descriptions for images (alt tags)</li>
              <li>Scalable text for better readability</li>
              <li>High contrast mode for improved visibility</li>
            </ul>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-3 font-alumnisans tracking-wide text-center sm:text-left">
              Feedback
            </h2>
            <p className="leading-relaxed font-light text-lg font-akshar tracking-wide text-center sm:text-left">
              If you encounter any accessibility barriers or have suggestions
              for improvement, please contact us at{" "}
              <a
                href="mailto:info@Travelnrelax.com"
                className="text-blue-600 underline hover:text-blue-700"
              >
                info@Travelnrelax.com
              </a>{" "}
              or call us at{" "}
              <a
                href="tel:303-317-6945"
                className="text-blue-600 underline hover:text-blue-700"
              >
                303-317-6945
              </a>
              .
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AccessibilityContent;

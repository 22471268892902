import React, { useEffect, useState } from "react";
import { fetchData, ApiResponse } from "../services/api"; // Assuming a fetchData service

const PromoBanner: React.FC = () => {
  const [planning, setPlanning] = useState<{
    text: string;
    description: string;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlanningData = async () => {
      try {
        const response: ApiResponse<{
          planning: { text: string; description: string }[];
        }> | null = await fetchData({
          url: "pages/get",
          data: { pageId: 17 },
        });

        if (response?.data?.planning?.[0]) {
          setPlanning(response.data.planning[0]);
        } else {
          throw new Error("No planning data found.");
        }
      } catch (err) {
        setError("Failed to load planning data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlanningData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-600">{error}</p>;
  }

  // Splitting the description into a list of sentences for mobile view
  const descriptionItems = planning?.description?.split(".").filter(Boolean) || [];

  return (
    <div className="p-3 flex mt-10 flex-col items-center justify-center py-4 sm:py-8 bg-white text-black">
      <h2 className="mt-2 sm:mt-5 text-xl lg:text-3xl font-semibold text-center font-akshar mb-2">
        {planning?.text || "Default Text for Planning"}
      </h2>
      <p
        className="mt-2 tracking-wide text-sm text-gray-700 mb-4 font-poppins text-justify sm:text-center mobile:hidden"
      >
        {planning?.description || "Default Description for Planning"}
      </p>
      {/* Mobile view */}
      <ul className="hidden mobile:flex flex-col gap-1 text-gray-700 text-sm font-poppins text-justify sm:text-center">
        {descriptionItems.map((item, index) => (
          <li key={index}>• {item.trim()}</li>
        ))}
      </ul>
    </div>
  );
};

export default PromoBanner;

import React from "react";
import NavMenu from "../components/NaveMenu";
import TabNavigation from "../components/TabNavigation";
import Trending from "../components/Trending";
import ContactAd from "../components/ContactAd";

const Inspiration: React.FC = () => {
  return (
    <div>
      <NavMenu />
      <TabNavigation />
      <Trending/>
      <ContactAd/>
   
    </div>
  );
};

export default Inspiration;

import React from "react";
import Navbar from "../components/Navbar";
import PlanningTrip from "../components/PlanningTrip";
import Clients from "../components/Clients";
import Footer from "../components/Footer";
import CustomerServiceContent from "../components/CustomerServiceContent";

const CustomerService: React.FC = () => {
  return (
  <div>
    <Navbar/>
    <CustomerServiceContent/>
    <PlanningTrip
        headingplanning="Where to next?
"
        paragraph="We’ll create a vacation you’ll remember forever."
      />
      <Clients/>
      <Footer/>
  </div>

  );
};

export default CustomerService;

import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import Footerlogo from "../assets/images/Footer-Logo.png";
import { Link } from "react-router-dom";

const NavMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    // Disable scrolling when the menu is open
    document.body.style.overflow = isOpen ? "hidden" : "auto";

    // Clean up on component unmount
    return () => {
      document.body.style.overflow = "auto"; // Ensure scrolling is restored
    };
  }, [isOpen]);

  return (
    
    <div>
      {/* Navbar */}
      <div className="flex justify-between overflow-y-hidden items-center py-4 px-4 md:px-28 bg-black">
        <div className="flex-shrink-0">
          <Link to="/">
            <img src={Footerlogo} alt="Logo" className="h-12 md:h-20" />
          </Link>
        </div>

        {/* Hamburger Menu (Visible on Mobile Only) */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white">
            <FaBars size={24} />
          </button>
        </div>

        {/* Main Navigation (Visible on larger screens) */}
        <div className="hidden md:flex items-center justify-center flex-grow space-x-16 text-neutral-100">
          <ul className="hidden md:flex items-center space-x-6 md:space-x-12 font-mplus2 text-sm md:text-lg">
            <li className="hover:text-white cursor-pointer">
              <div className="border border-[#FFFFFF] rounded-full p-1 flex items-center justify-center">
                <CiSearch size={20} />
              </div>
            </li>
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
              >
                Home
              </NavLink>
            </li>
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/destination"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
              >
                Destination
              </NavLink>
            </li>
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/experiences"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
              >
                Experiences
              </NavLink>
            </li>
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/month"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
              >
                Month
              </NavLink>
            </li>
          </ul>
        </div>

        {/* Travel Button */}
        <div className="hidden md:block">
          <button className="bg-[#01316E] text-white py-2 px-4 rounded-lg hover:bg-blue-500 font-mplus2">
            Ready To Travel?
          </button>
        </div>
      </div>

      {/* Full-Screen Mobile Menu (Visible when isOpen is true) */}
      {isOpen && (
        <div className="fixed inset-0 z-40 bg-black bg-opacity-90 flex flex-col items-center justify-center text-white ">
          <button
            className="absolute top-4 right-4 text-white text-2xl"
            onClick={toggleMenu}
          >
            &times;
          </button>
          <ul className="space-y-4 text-center">
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
                onClick={() => setIsOpen(false)} // Close menu on selection
              >
                Home
              </NavLink>
            </li>
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/destination"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
                onClick={() => setIsOpen(false)} // Close menu on selection
              >
                Destination
              </NavLink>
            </li>
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/experiences"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
                onClick={() => setIsOpen(false)} // Close menu on selection
              >
                Experiences
              </NavLink>
            </li>
            <li className="hover:text-white uppercase cursor-pointer">
              <NavLink
                to="/month"
                className={({ isActive }) =>
                  isActive ? "border-b-2 border-white pb-1" : ""
                }
                onClick={() => setIsOpen(false)} // Close menu on selection
              >
                Month
              </NavLink>
            </li>
            <li>
              <button className="bg-[#01316E] text-white py-2 px-4 rounded-lg hover:bg-blue-500 font-mplus2">
                Ready To Travel?
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavMenu;

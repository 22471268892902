import React from "react";
import AboutView from "../assets/images/AboutusView.png";

const OurPhilosophy: React.FC = () => {
  return (
    <div className="relative mt-12 sm:mt-24">
      {/* Background Image */}
      <img
        src={AboutView}
        alt="Seamless Travel"
        className="w-full h-[250px] sm:h-[500px] object-cover"
      />

      {/* Overlay Content */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="bg-white bg-opacity-70 text-center p-4 sm:p-8 rounded-md border-0 sm:border-2 sm:border-black sm:border-dashed w-[90%] sm:w-[65%] max-w-[800px]">
          <p className="tracking-normal font-poppins leading-5 sm:leading-8 text-xs sm:text-base text-justify sm:text-center text-black">
            For us, we do more than just offer vacation booking services. We aim
            to provide fully customized vacation bookings while facilitating
            sincere relationships between travelers and their host destinations
            and communities.
          </p>
          <p className="tracking-normal mt-5 sm:mt-7 font-poppins leading-5 sm:leading-8 text-xs sm:text-base text-justify sm:text-center text-black">
            Operating within our cherished principles of honor, diligence, and
            legacy, our team helps you save time, money, and resources during
            your trip. We offer customized vacation packages tailored to each
            traveler’s satisfaction.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurPhilosophy;

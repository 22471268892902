import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { AxiosRequestConfig } from "axios";

interface BlogDetailsResponse {
  success: boolean;
  message: string;
  error_code: number;
  data: {
    blogId: string;
    title: string;
    description: string;
    image: string;
    createdAt: string;
    authorName: string | null;
    categories: string;
  }[];
}

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const BlogDetails: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [blog, setBlog] = useState<BlogDetailsResponse["data"][0] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!state || !state.blogId) {
      navigate("/blogs");
      return;
    }

    const fetchBlogDetails = async () => {
      const config: AxiosRequestConfig = {
        url: `/Blogs/details/${state.blogId}`,
        headers: {
          token: STATIC_TOKEN,
          "Content-Type": "application/json",
        },
      };

      setLoading(true);
      try {
        const baseUrl = "https://demo.decordtech.com/tadmin/api/";
        const response = await axios.post<BlogDetailsResponse>(
          baseUrl + config.url,
          {},
          { headers: config.headers }
        );

        if (response.data.success && response.data.data.length > 0) {
          setBlog(response.data.data[0]);
        } else {
          setError(response.data.message || "Failed to load blog details.");
        }
      } catch (err) {
        setError("An unexpected error occurred while fetching blog details.");
        console.error("Error fetching blog details:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [state, navigate]);

  if (loading) {
    return <p className="text-center text-gray-500">Loading blog details...</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  if (!blog) {
    return <p className="text-center text-gray-500">Blog not found.</p>;
  }

  return (
    <div className="bg-white min-h-screen py-10 px-4 sm:px-8 lg:px-16">
      <div className="relative">
        <img
          src={blog.image}
          alt={blog.title}
          className="w-full h-[30rem] object-cover"
        />
        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/70 to-transparent text-white p-6 sm:p-8">
          <h1 className="text-4xl font-bold font-serif font-alumnisans">{blog.title}</h1>
          <p className="mt-3 text-sm font-light font-alumnisans">
            Published on {new Date(blog.createdAt).toLocaleDateString()} by{" "}
            <span className="font-medium">{blog.authorName || "Unknown"}</span>
          </p>
        </div>
      </div>

      {/* Blog Content Section */}
      <div className="mt-8">
        {/* Categories */}
        <div className="mb-8">
          <h2 className="text-lg font-semibold text-gray-800 font-sans">
            Categories
          </h2>
          <div className="flex flex-wrap gap-3 mt-4">
            {JSON.parse(blog.categories).map(
              (category: string, idx: number) => (
                <span
                  key={idx}
                  className="bg-gradient-to-r font-mplus2 from-blue-500 to-purple-500 text-white px-4 py-1 text-sm rounded-full shadow-md font-medium hover:shadow-lg transition"
                >
                  {category}
                </span>
              )
            )}
          </div>
        </div>

        {/* Blog Description */}
        <div className="text-gray-700 leading-relaxed prose prose-lg">
          <h2 className="text-2xl font-bold mb-5">Blog Details</h2>
          <p className="whitespace-pre-wrap font-akshar">{blog.description}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;

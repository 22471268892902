import React from "react";
import Planningtrip from "../assets/images/Planningtrip.png";
import Scroll from "../assets/images/scroll.svg";

interface PlanningTripProps {
  headingplanning: string;
  paragraph: string;
}

const PlanningTrip: React.FC<PlanningTripProps> = ({
  headingplanning,
  paragraph,
}) => {
  return (
    <section className="mt-8 sm:mt-12">
      <div className="relative w-screen">
        {/* Planning Trip Background Image */}
        <img
          src={Planningtrip}
          alt="Seamless Travel"
          className="w-full h-[200px] sm:h-[300px] md:h-[370px] lg:h-[400px] object-cover"
        />

        {/* Scroll Icon */}
        {/* <div className="absolute top-2 left-1/2 transform -translate-x-1/2 z-10 ">
          <img
            src={Scroll}
            alt="Scroll Icon"
            className="w-6 sm:w-12 md:w-20 lg:w-24 object-cover"
          />
        </div> */}

        {/* Content on Top of the Image */}
        <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white px-4 sm:px-8">
          <h1 className="text-base sm:text-2xl md:text-4xl lg:text-3xl font-akshar uppercase tracking-wide font-bold mb-2 leading-tight sm:leading-snug">
            {headingplanning}
          </h1>
          <p className="text-xs sm:text-lg md:text-xl lg:text-lg uppercase font-poppins tracking-wide leading-relaxed  sm:mt-4 mb-2">
            {paragraph}
          </p>
          <button className="mt-3 py-2 sm:py-3 px-4 sm:px-6 md:px-8 uppercase border border-white text-white font-bold tracking-wide rounded-md text-xs sm:text-sm md:text-base lg:text-lg hover:bg-white hover:text-black transition">
            Plan my trip
          </button>
        </div>
      </div>
    </section>
  );
};

export default PlanningTrip;

import React, { useEffect, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";
import Profile from "../assets/images/TestimonialProfile.png"; // Default Profile Image

// Define the Testimonial interface
interface Testimonial {
  id: string;
  name: string;
  description: string;
  rating: string;
  image: string;
}

// Define the API response interface
interface TestimonialApiResponse {
  success: boolean;
  code: string;
  message: string;
  testimonials: Testimonial[];
}

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const TestimonialView: React.FC = () => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTestimonials = async () => {
      const config: AxiosRequestConfig = {
        url: "testimonials/testimonial_list",
        data: {},
        headers: {
          token: STATIC_TOKEN,
          "Content-Type": "application/json",
        },
      };

      setLoading(true);
      try {
        const baseUrl = "https://demo.decordtech.com/tadmin/api/";
        const response = await axios.post<TestimonialApiResponse>(
          `${baseUrl}${config.url}`,
          config.data,
          {
            headers: config.headers,
          }
        );

        if (response.data?.success && response.data?.testimonials) {
          setTestimonials(response.data.testimonials);
        } else {
          setError(response.data?.message || "Failed to load testimonials.");
        }
      } catch (error) {
        setError("An unexpected error occurred while fetching testimonials.");
        console.error("Error fetching testimonials:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  return (
    <div>
      <h2 className="text-3xl  font-akshar font-bold mt-10 sm:mt-20 text-center mb-6 sm:mb-8 uppercase tracking-wide">
        Testimonials
      </h2>
      <div className="px-4 sm:px-10 md:px-20 py-10 sm:py-20">
    
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-16 sm:gap-10">
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className="col-span-1">
                <div className="bg-white shadow-lg border border-gray-100 rounded-lg p-4 sm:p-6 max-w-xs mx-auto flex flex-col items-center">
                  <div className="w-28 h-28 rounded-full overflow-hidden -mt-14 shadow-md">
                    <img
                      src={testimonial.image || Profile}
                      alt={testimonial.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <h3 className="text-md mt-2 sm:text-lg uppercase font-semibold  font-alumnisans text-center">
                  {testimonial.name}
                </h3>
                <p className="text-sm text-gray-700 mb-4 font-poppins text-justify sm:text-center mt-4">
                  {testimonial.description}
                </p>
                  <div className="flex items-center mt-2 space-x-1">
                    {[...Array(5)].map((_, index) => (
                      <svg
                        key={index}
                        className={`w-5 h-5 ${
                          index < parseInt(testimonial.rating || "0")
                            ? "text-yellow-400"
                            : "text-gray-300"
                        }`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.518 4.674a1 1 0 00.95.69h4.9c.967 0 1.371 1.24.588 1.81l-3.97 2.879a1 1 0 00-.364 1.118l1.518 4.674c.3.921-.755 1.688-1.538 1.118l-3.97-2.879a1 1 0 00-1.175 0l-3.97 2.879c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.364-1.118L2.993 9.1c-.783-.57-.379-1.81.588-1.81h4.9a1 1 0 00.95-.69l1.518-4.674z" />
                      </svg>
                    ))}
                  </div>
                  <p className="text-xs font-semibold text-gray-700 mt-2 mb-2 font-mplus2">28 AUG 2024</p>
                </div>
              </div>
            ))}
          </div>
        
      </div>
    </div>
  );
};

export default TestimonialView;

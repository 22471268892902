import React, { useState,useEffect } from "react";
import logo from "../assets/images/logo.svg";
import background from "../assets/images/Bgmonth.png";
import { FaBars, FaTimes, FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { GrLinkedinOption } from "react-icons/gr";

interface NavbarProps {
  heading?: string;
  subHeading?: string;
}

const HeaderMonthly: React.FC<NavbarProps> = ({ heading, subHeading }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };
  useEffect(() => {
    // Disable scrolling when the menu is open
    document.body.style.overflow = isOpen ? "hidden" : "auto";

    // Clean up on component unmount
    return () => {
      document.body.style.overflow = "auto"; // Ensure scrolling is restored
    };
  }, [isOpen]);

  return (
    <nav
      className="w-full top-0 bg-transparent z-10 relative h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${background})` }}
    >
      {/* Overlay text section */}
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center z-10 text-white text-center px-4 sm:px-0">
        <h1 className="tracking-widest uppercase text-4xl sm:text-6xl font-bold font-alumnisans">
          {heading || "March"}
        </h1>
        {subHeading && <p className="text-lg">{subHeading}</p>}
      </div>

      {/* Center-aligned Navbar */}
      <div className="flex items-center justify-between z-20 md:px-20 relative w-full px-4 md:px-0">
        {/* Logo */}
        <Link to="/" className="flex-shrink-0">
          <img src={logo} alt="Logo" className="h-16 w-16 md:h-24 md:w-24" />
        </Link>

        {/* Desktop Navbar */}
        <ul className="hidden md:flex items-center space-x-6 lg:space-x-16 text-neutral-100">
          <li className="hover:text-white cursor-pointer">
            <Link to="/" className="p-2">HOME</Link>
          </li>
          <li className="relative group uppercase cursor-pointer">
            <Link to="/destination" className="p-2">Destination</Link>
          </li>
          <li className="relative group uppercase cursor-pointer">
            <Link to="/experiences" className="p-2">Experiences</Link>
          </li>

          <li className="text-white font-bold text-sm lg:text-base">303-317-6945</li>
          <button className="bg-blue-900 text-white py-1 sm:py-2 px-3 sm:px-4 rounded-lg hover:bg-blue-500 text-xs sm:text-sm lg:text-base">
            Ready To Travel?
          </button>
        </ul>

        <div className="md:hidden flex justify-end">
          <button onClick={toggleMenu} className="focus:outline-none text-white" aria-label="Toggle menu">
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="fixed inset-0 z-40 flex flex-col items-center justify-center bg-blue-900">
          <button
            className="absolute top-4 right-4 text-white"
            onClick={toggleMenu}
            aria-label="Close menu"
          >
            <FaTimes size={24} />
          </button>
          <ul className="space-y-4 text-lg font-bold text-white text-center">
            <li>
              <Link to="/" onClick={toggleMenu} className="hover:text-blue-700">Home</Link>
            </li>
            <li>
              <Link to="/destination" onClick={toggleMenu} className="hover:text-blue-700">Destination</Link>
            </li>
            <li>
              <Link to="/experiences" onClick={toggleMenu} className="hover:text-blue-700">Experiences</Link>
            </li>
            <button className="mt-12 w-full py-4 px-4 bg-yellow-500 text-blue-900 font-bold text-xl rounded-lg hover:bg-yellow-400 transition duration-300">
              Ready To Travel?
            </button>
          </ul>
        </div>
      )}

      {/* Floating Social Media Icons */}
      <div className="absolute left-8 bottom-8 flex flex-col items-center space-y-2 text-white sm:left-4 sm:bottom-4">
        <a href="#" className="text-white font-bold block border border-[#FFFFFF] rounded-full p-2 sm:p-3">
          <FaFacebookF />
        </a>
        <a href="#" className="text-white font-bold block border border-[#FFFFFF] rounded-full p-2 sm:p-3">
          <FaInstagram />
        </a>
        <a href="#" className="text-white font-bold block border border-[#FFFFFF] rounded-full p-2 sm:p-3">
          <GrLinkedinOption />
        </a>
        <a href="#" className="text-white font-bold block border border-[#FFFFFF] rounded-full p-2 sm:p-3">
          <FaTwitter />
        </a>
      </div>
    </nav>
  );
};

export default HeaderMonthly;

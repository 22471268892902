import React, { useState } from "react";
import axios from "axios";
import { GoClock } from "react-icons/go";
import contactimage from "../assets/images/Contactus.png";

const STATIC_TOKEN =
  "N2o0ZjZlZVFBRUxVL1laanlBczQ0UT09OjrGQtx134tUoB8T7vu8HoWyMTN3NjlhZWQrNDMyZGpydGVRR3VIQT09OjqOhX0XNADMMEojPyPpiZlA";

const RegisterContact: React.FC = () => {
  const initialFormState = {
    fname: "",
    lname: "",
    email: "",
    conf_email: "",
    phone: "",
    start_date: "",
    end_date: "",
    destination: "",
    duration: 5,
    people: 2,
    per_head: 2000,
    comments: "",
  };

  const [formData, setFormData] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const formatDateTimeForBackend = (datetime: string) => {
    const date = new Date(datetime);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: id === "destination" ? parseInt(value, 10) || "" : value, // Ensure destination is an integer
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage(null);
    setError(null);

    const formattedData = {
      ...formData,
      start_date: formatDateTimeForBackend(formData.start_date),
      end_date: formatDateTimeForBackend(formData.end_date),
    };

    try {
      const response = await axios.post(
        "https://demo.decordtech.com/tadmin/api/enquiries/register",
        formattedData,
        {
          headers: {
            "Content-Type": "application/json",
            token: STATIC_TOKEN,
          },
        }
      );

      if (response.status === 200) {
        setResponseMessage(response.data.message);
        setFormData(initialFormState); // Reset the form after successful submission
      } else {
        setError("Failed to register. Please try again.");
      }
    } catch (err) {
      console.error("Error submitting form:", err);
      setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-12 md:px-20">
      <div>
        <div className="text-center mt-2 sm:mt-5 p-3  text-xl  lg:text-xl font-semibold font-akshar">
          If you’re ready to get away and start your dream <br />
          vacation, give us a call at
        </div>
        <div className="grid grid-cols-12 gap-8 mt-10">
          {/* Form Section */}
          <div className="col-span-12 md:col-span-8 p-5 border rounded-lg shadow-lg bg-white">
            <h4 className="font-akshar uppercase border-b font-semibold  text-xl">
              Your Trip
            </h4>
            <form onSubmit={handleSubmit} className="space-y-6 mt-5">
              {/* Input fields */}
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label
                    htmlFor="fname"
                    className="block font-anekbangla font-semibold text-gray-700 mb-2"
                  >
                    Your First Name*
                  </label>
                  <input
                    id="fname"
                    type="text"
                    value={formData.fname}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="lname"
                    className="block font-anekbangla font-semibold text-gray-700 mb-2"
                  >
                    Your Last Name*
                  </label>
                  <input
                    id="lname"
                    type="text"
                    value={formData.lname}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block font-anekbangla font-semibold text-gray-700 mb-2"
                >
                  Your Email Address*
                </label>
                <input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="conf_email"
                  className="block font-anekbangla font-semibold text-gray-700 mb-2"
                >
                  Confirm Email Address*
                </label>
                <input
                  id="conf_email"
                  type="email"
                  value={formData.conf_email}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="block font-anekbangla font-semibold text-gray-700 mb-2"
                >
                  Phone Number*
                </label>
                <input
                  id="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="destination"
                  className="block font-anekbangla font-semibold text-gray-700 mb-2"
                >
                  Destination (ID)*
                </label>
                <input
                  id="destination"
                  type="number"
                  value={formData.destination}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="start_date"
                  className="block font-anekbangla font-semibold text-gray-700 mb-3"
                >
                  Start Date*
                </label>
                <input
                  id="start_date"
                  type="datetime-local"
                  value={formData.start_date}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="end_date"
                  className="block font-anekbangla font-semibold text-gray-700 mb-2"
                >
                  End Date*
                </label>
                <input
                  id="end_date"
                  type="datetime-local"
                  value={formData.end_date}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

              {/* Submit button */}
              <button
                type="submit"
                disabled={loading}
                className="bg-black text-white font-bold py-3 px-6 rounded"
              >
                {loading ? "Submitting..." : "Submit Enquiry"}
              </button>
            </form>
            {responseMessage && (
              <p className="text-green-500 mt-4">{responseMessage}</p>
            )}
            {error && <p className="text-red-500 mt-4">{error}</p>}
          </div>

          {/* Office Hours Section */}
          <div className="col-span-12 md:col-span-4">
            <div className="bg-white shadow-lg p-5 rounded-lg border">
              <div className="flex flex-col items-center">
                <GoClock size={36} />
                <h3 className="uppercase mt-3  text-xl  lg:text-xl font-semibold font-akshar">
                  Office Hours
                </h3>
                <ul className="mt-4 font-poppins  font-medium">
                  <li>Monday - Friday: 9am - 10pm</li>
                  <li>Saturday - Sunday: Closed</li>
                </ul>
              </div>
              <img src={contactimage} alt="Contact Us" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterContact;
